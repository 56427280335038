<template>
<div class="container">
  <div class="top" id="bloc-0">
    <voice-header></voice-header>
  </div>
  <div class="pricing-container">
    <div class="pricing-header">
       <div class="pricing-title">
         <h1>{{ $t('pricing.main_title') }}</h1>
         <p v-if="$store.state.lang === 'zh'" class="china-payment-notice" @click="showMainlandPopup">
           使用 
           <span class="payment-method">
             <img src="/ssr/img/payment/alipay.svg" alt="支付宝" class="payment-icon">
           </span>支付宝或
           <span class="payment-method">
             <img src="/ssr/img/payment/wechat.svg" alt="微信支付" class="payment-icon">
           </span>微信支付? 点此付款 
           <svg class="arrow-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>
         </p>
       </div>
       
       <div class="billing-toggle-wrapper">
         <div class="billing-toggle">
           <div class="toggle-text-left">
             <span class="toggle-text">{{ $t('pricing.period_month') }}</span>
           </div>
           <div class="switch-container">
             <label class="switch">
               <input type="checkbox" v-model="isYearly">
               <span class="slider round"></span>
             </label>
           </div>
           <div class="toggle-text-right">
             <span class="toggle-text">{{ $t('pricing.period_year') }}</span>
             <span class="yearly-discount" :class="{ 'monthly': !isYearly }">{{ $t('pricing.discount_quantity') }}</span>
           </div>
         </div>
       </div>
    </div>
    <div class="pricing-plans">
      <div v-for="plan in currentPlans" :key="plan.name" class="plan" :class="{ 'popular': plan.isPopular }">
        <div v-if="plan.isPopular" class="popular-tag">
          <div class="popular-tag-row">
            <div class="sale-title">
              <div class="offer-ends">{{ $t('pricing.black_friday_sale') }}</div>
            </div>
            <div class="timer">
              <span class="time-block">{{ minutes }}</span>
              <span class="time-separator">:</span>
              <span class="time-block">{{ seconds }}</span>
              <span class="time-separator">:</span>
              <span class="time-block milliseconds">{{ milliseconds }}</span>
            </div>
          </div>
          <div class="countdown-timer">
          </div>
        </div>
        <h2>{{ plan.name }}</h2>
        <p>{{ plan.description }}</p>
        <div class="price-container">
          <div class="price-row">
            <span class="price-number">${{ plan.price }}</span>
            <span class="period">/{{ $t('pricing.period_month_short') }}</span>
          </div>
          <div class="billed-yearly-container">
            <p v-if="plan.price > 0.1" class="billed-yearly" id="starter-billed">
              <span v-if="isYearly && plan.price_year_total">
                ${{ plan.price_year_total }} {{ $t('pricing.billed_year') }}
                <span class="original-price">${{ plan.price_year_total * 2 }}</span>
              </span>
              <span v-else-if="plan.price > 0.1">
                <span class="original-price">${{ plan.price * 2 }}</span>
              </span>
              <span v-else>&nbsp;</span>
            </p>
          </div>
        </div>
	      <div v-if="plan.price > 0.1">
          <button 
            class="get-started-btn" 
            :class="{ 'popular-btn': plan.isPopular }" 
            @click.prevent="createCheckoutSession(plan)"
          >
            {{ $t('pricing.buy_btntext') }}
          </button>
        </div>
        <div class="features">
          <ul>
            <li v-for="feature in plan.features" :key="feature" :class="{ 'highlight-feature': feature.includes('1000 characters') || feature.includes('5 generations') }">
              {{ feature }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <GoogleSignInModal 
       :visible.sync="isSignInShow"
       @credential-response="handleCredentialResponse"
       @close="closePriceSignIn"
     />
    <PopupMainland 
      :visible="showMainlandPayment"
      :onCheckout="createCheckoutSession"
      @close="closeMainlandPopup"
    />
  </div>
  
  <div class="brands-section">
    <h2 class="brands-title">{{ $t('pricing.brands_title') }}</h2>
    <div class="brands-carousel">
      <div class="brands-track">
        <div class="brand-item">
          <img src="/ssr/img/brands/discord.svg" alt="Discord" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/facebook.svg" alt="Facebook" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/instagram.svg" alt="Instagram" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/snapchat.svg" alt="Snapchat" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/tiktok.svg" alt="TikTok" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/twitch.svg" alt="Twitch" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/vimeo.svg" alt="Vimeo" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/youtube.svg" alt="YouTube" />
        </div>
      </div>
    </div>
  </div>

  <div class="testimonials-section">
    <h2 class="testimonials-title">{{ $t('pricing.testimonials_title') }}</h2>
    <div class="rating-container">
      <div class="stars">
        <span class="star">★</span>
        <span class="star">★</span>
        <span class="star">★</span>
        <span class="star">★</span>
        <span class="star last">★</span>
      </div>
      <span class="rating-text">4.8</span>
    </div>
    <div class="testimonial-grid">
      <div class="testimonial-card">
        <div class="testimonial-header">
          <img src="/ssr/img/avatars/user1.jpg" alt="User avatar" class="avatar">
          <div class="user-info">
            <div class="user-name">Emma Thompson</div>
            <div class="user-title">Content Creator</div>
          </div>
        </div>
        <p class="testimonial-text">"The emotional depth of the AI voices is incredible! My storytelling videos now connect with viewers on a whole new level. Engagement up by 280% and gained 15K followers in just 3 weeks. Game-changer!"</p>
      </div>
      
      <div class="testimonial-card">
        <div class="testimonial-header">
          <img src="/ssr/img/avatars/user2.jpg" alt="User avatar" class="avatar">
          <div class="user-info">
            <div class="user-name">David Miller</div>
            <div class="user-title">Audiobook Producer</div>
          </div>
        </div>
        <p class="testimonial-text">"The natural flow and emotional range of these AI voices amazed me. My latest audiobook hit $6,000 in sales within the first month. The quality rivals professional voice actors!"</p>
      </div>
      
      <div class="testimonial-card">
        <div class="testimonial-header">
          <img src="/ssr/img/avatars/user3.jpg" alt="User avatar" class="avatar">
          <div class="user-info">
            <div class="user-name">Rachel Anderson</div>
            <div class="user-title">E-Learning Director</div>
          </div>
        </div>
        <p class="testimonial-text">"Student engagement skyrocketed after implementing these AI voices in our courses. Completion rates up 45%, and students consistently praise the natural, engaging delivery of our content."</p>
      </div>

      <div class="testimonial-card">
        <div class="testimonial-header">
          <img src="/ssr/img/avatars/user4.jpg" alt="User avatar" class="avatar">
          <div class="user-info">
            <div class="user-name">Destiny Williams</div>
            <div class="user-title">Marketing Director</div>
          </div>
        </div>
        <p class="testimonial-text">"Our ad campaign performance doubled after switching to these AI voices. The emotional connection they create with audiences is remarkable. ROI increased by 180% across all platforms."</p>
      </div>

      <div class="testimonial-card">
        <div class="testimonial-header">
          <img src="/ssr/img/avatars/user5.jpg" alt="User avatar" class="avatar">
          <div class="user-info">
            <div class="user-name">Yuki Tanaka</div>
            <div class="user-title">ポッドキャスト制作者</div>
          </div>
        </div>
        <p class="testimonial-text">"AIボイスの自然な抑揚と感情表現に驚きました。リスナーからの反応が劇的に改善し、わずか2ヶ月で視聴者数が3倍に。制作時間も大幅に短縮できて、本当に助かっています。"</p>
      </div>

      <div class="testimonial-card">
        <div class="testimonial-header">
          <img src="/ssr/img/avatars/user6.jpg" alt="User avatar" class="avatar">
          <div class="user-info">
            <div class="user-name">Carlos Rodríguez</div>
            <div class="user-title">Creador de Contenido</div>
          </div>
        </div>
        <p class="testimonial-text">"¡Increíble herramienta! La calidad emocional de las voces transformó mis videos. El engagement aumentó un 200% y mis seguidores se duplicaron en un mes. La mejor inversión para mi contenido."</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex';
import VoiceHeader from '../components/VoiceHeader.vue';
import { handleGoogleAuth } from '../utils/auth'
import { setCookie, delCookie, getCookie } from '../utils/cookies';
import { reportError } from '../utils/errorReporter'
import { trackAction } from '../utils/actionReporter'
import PopupMainland from '../components/PopupMainland.vue'
import GoogleSignInModal from '../components/GoogleSignInModal.vue'

export default {
  name: 'pricing',
  components: {
    VoiceHeader,
    GoogleSignInModal,
    PopupMainland
  },
  data() {
    return {
      host: "https://tiktokvoice.net",
      isSignInShow: false,
      pendingPlan: null, // 添加这一行
      isLoginInProgress: false, 
      isYearly: true,
      monthlyPlans: [
        {
          name: this.$i18n.t('pricing.sub_free_name'),
          description: this.$i18n.t('pricing.sub_free_desc'),
          price: '0',
          isPopular: false,
          type_name: 'free_month',
          subscript_type: '0',
          features: this.$i18n.t('pricing.sub_free_content'),
        },
        {
          name: this.$i18n.t('pricing.sub_starter_name'),
          description: this.$i18n.t('pricing.sub_starter_desc'),
          price: '7.9',
          isPopular: true,
          type_name: 'starter_month',
          features: this.$i18n.t('pricing.sub_starter_content'),
        },
        {
          name: this.$i18n.t('pricing.sub_pro_name'),
          description: this.$i18n.t('pricing.sub_pro_desc'),
          price: '21.9',
          isPopular: false,
          type_name: 'pro_month',
          features: this.$i18n.t('pricing.sub_pro_content'),
        }
      ],
      yearlyPlans: [
        {
          name: this.$i18n.t('pricing.sub_free_name'),
          description: this.$i18n.t('pricing.sub_free_desc'),
          price: '0',
          isPopular: false,
          type_name: 'free_year',
          features: this.$i18n.t('pricing.sub_free_content'),
        },
        {
          name: this.$i18n.t('pricing.sub_starter_name'),
          description: this.$i18n.t('pricing.sub_starter_desc'),
          price: '4.9',
          price_year_total: '58.8',
          isPopular: true,
          type_name: 'starter_year',
          features: this.$i18n.t('pricing.sub_starter_content'),
        },
        {
          name: this.$i18n.t('pricing.sub_pro_name'),
          description: this.$i18n.t('pricing.sub_pro_desc'),
          price: '12.9',
          price_year_total: '154.8',
          isPopular: false,
          type_name: 'pro_year',
          features: this.$i18n.t('pricing.sub_pro_content'),
        }
      ],
      minutes: '03',
      seconds: '00',
      milliseconds: '0',
      countdownInterval: null,
      showMilliseconds: true,
      errorHandler: null,
      rejectionHandler: null,
      showMainlandPayment: false,
    }
  },
  computed: {
    currentPlans() {
      return this.isYearly ? this.yearlyPlans : this.monthlyPlans;
    },
    ...mapGetters(['isLoggedIn', 'currentUser'])
  },
  head() {
    return {
      'title': this.$i18n.t('pricing.pricing_title'),
      'keywords': this.$i18n.t('txt2voice.keywords'),
      'description': this.$i18n.t('pricing.pricing_desc'),
    }
  },
  asyncData: function ({ store, route }) {
    // console.log('home state count:' + store.state.count)
    return store.dispatch("fetchData")
  },
  methods: {
    // 新增重置方法
    resetSignInState() {
      this.isSignInShow = false;
      this.isLoginInProgress = false;
      this.pendingPlan = null;
    },
    closePriceSignIn() {
      this.resetSignInState();
      // 给系统一点时间完全清理状态
      setTimeout(() => {
        this.isLoginInProgress = false;
      }, 100);
    },
    async handleCredentialResponse(response) {
      const apiUrl = this.host + "/lapi/auth/google"
      // console.log("handleCredentialResponse 11, response:", response)
      const success = await handleGoogleAuth.handleCredentialResponse(response, this.$store, apiUrl)
      // console.log("handleCredentialResponse 22, success:", success)
      if (success) {
        if (this.pendingPlan) {
          const plan = this.pendingPlan
          this.resetSignInState();
          await this.createCheckoutSession(plan)
          trackAction({
            email: this.currentUser?.email,
            action: 'pricing-login-success',
            domain: 'tiktokvoice.net',
            modelcat: plan.type_name,
            modelname: ''
          });
        }
        this.resetSignInState();   
      } else {
        this.resetSignInState();
        reportError(new Error('Pricing login Authentication failed'), 'handleCredentialResponse failed')
      }
    },
    async createCheckoutSession(plan) {
      if (!plan || !plan.type_name) {
        reportError(new Error('Invalid plan data'), "createCheckoutSession");
        return;
      }

      if (this.isLoginInProgress) {
        return;
      }

      if (!this.isLoggedIn) {
        this.showMainlandPayment = false
        this.isLoginInProgress = true;
        this.pendingPlan = plan;
        this.isSignInShow = true;
        trackAction({
          email: "",
          action: 'pricing-popup-login',
          domain: 'tiktokvoice.net',
          modelcat: plan.type_name,
          modelname: ''
        });
        return;
      }

      const uri = this.host + '/lapi/cpaysess';
      try {
        const email = this.currentUser?.email || '';
        const formData = new FormData();
        formData.append('type_name', plan.type_name);
        formData.append('email', email);
        var success_url = '/' + this.$store.state.lang + '/pay/success'
        formData.append('success_url', success_url)
        var cancel_url = '/' + this.$store.state.lang + '/pricing'
        formData.append('cancel_url', cancel_url)
        const headers = {
          'Content-Type': 'multipart/form-data'
        }
        //console.log('start post!')
        const res = await axios.post(uri, formData, headers)
        // 处理响应, 例如重定向Stripe Checkout页面
        // console.log('respose ret:', res.data.ret)
        // console.log('respose url:', res.data.url)
        if (res.data.ret === 0){
          window.location.href = res.data.url
          trackAction({
            email: this.currentUser?.email,
            action: 'pricing-checkout-success',
            domain: 'tiktokvoice.net',
            modelcat: plan.type_name,
            modelname: ''
          });
        } else {
          //console.log('msg:' + res.data.msg)
          reportError(new Error(res.data.msg), "createCheckoutSession")
        }
      } catch (error) {
        // console.error('Error creating checkout session:', error)
        reportError(error, "createCheckoutSession error")
      }
    },
    startCountdown() {
      let totalMilliseconds = 180000; // 3 minutes in milliseconds
      this.countdownInterval = setInterval(() => {
        if (totalMilliseconds > 0) {
          totalMilliseconds -= 50; // Decrease by 50ms each time
          const minutes = Math.floor(totalMilliseconds / 60000);
          const seconds = Math.floor((totalMilliseconds % 60000) / 1000);
          const ms = Math.floor((totalMilliseconds % 1000) / 100); // Only get first digit of milliseconds
          
          this.minutes = String(minutes).padStart(2, '0');
          this.seconds = String(seconds).padStart(2, '0');
          this.milliseconds = String(ms);
        } else {
          clearInterval(this.countdownInterval);
          this.minutes = '03';
          this.seconds = '00';
          this.milliseconds = '0';
          this.startCountdown();
        }
      }, 50); // Update every 50ms for smoother animation
    },
    showMainlandPopup() {
      this.showMainlandPayment = true
    },
    closeMainlandPopup() {
      this.showMainlandPayment = false
    },
    handleMainlandPayment(planId) {
      // 处理大陆支付逻辑
      this.showMainlandPayment = false
    }
  },
  mounted() {
    this.startCountdown()
    
    // 复制品牌轮播内容以实现无缝效果
    const brandsTrack = document.querySelector('.brands-track')
    if (brandsTrack) {
      const items = brandsTrack.innerHTML
      brandsTrack.innerHTML = items + items
    }
           // 使用箭头函数保持this上下文
    this.errorHandler = (event) => reportError(event.error, 'window.error');
    this.rejectionHandler = (event) => reportError(event.reason, 'unhandledrejection');
    // 添加全局错误监听
    window.addEventListener('error', this.errorHandler);
    window.addEventListener('unhandledrejection', this.rejectionHandler);
  },
  beforeDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    if (this.errorHandler) {
      window.removeEventListener('error', this.errorHandler);
    }
    if (this.rejectionHandler) {
      window.removeEventListener('unhandledrejection', this.rejectionHandler);
    }
  }
}
</script>

<style scoped>
@import '/ssr/css/pricing_third.css';
</style>
